import { ElementIconType } from "@/components/ui/icons";
import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import { selectCadAlignmentErrorMessage } from "@/store/cad/cad-slice";
import { isIElementModel3dStream } from "@faro-lotv/ielement-types";
import {
  compatibilityMessage,
  selectDisableCaptureTreeAlignment,
} from "../../cad-model-tree/use-disable-capture-tree-alignment";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";

export const OPEN_ALIGN_WIZARD_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.openAlignmentWizard,
  label: "Align",
  icon: ElementIconType.AlignIcon,
  handler: ({ elementID, dispatch }) =>
    openAlignmentWizard({ elementIdToAlign: elementID, dispatch }),
  disabledMessageForNode: (element, state) => {
    if (selectDisableCaptureTreeAlignment(state)) {
      return compatibilityMessage;
    }
    if (isIElementModel3dStream(element)) {
      return selectCadAlignmentErrorMessage(state);
    }
  },
};
