import { useTransparencySettingsContext } from "@/components/common/transparency-sliders/transparency-settings-context";
import { useStoreCameraWithCustomControls } from "@/components/common/view-runtime-context";
import { CameraAnimation } from "@/components/r3f/animations/camera-animation";
import { useClippingPlanes } from "@/hooks/use-clipping-planes";
import { useDetectTooManyRedraws } from "@/hooks/use-detect-too-many-redraws";
import { use3DmodelsBoundingBox } from "@/hooks/use-object-bounding-box";
import { useRealtimeRaycasting } from "@/hooks/use-real-time-raycasting";
import { ModeSceneProps, SceneFilterLookAtInitialState } from "@/modes/mode";
import { useCurrentScene } from "@/modes/mode-data-context";
import {
  CadModelObject,
  PointCloudObject,
  SheetObject,
  useCached3DObjectIfExists,
  useCached3DObjectIfReady,
  usePreload3DObjects,
} from "@/object-cache";
import { UnknownObject } from "@/object-cache-type-guard";
import { selectMeasurements } from "@/store/measurement-tool-selector";
import { changeMode } from "@/store/mode-slice";
import {
  selectOverviewIsCadSelected,
  selectOverviewIsPointCloudSelected,
  setOverviewSceneFilter,
} from "@/store/modes/overview-mode-slice";
import { setWalkSceneFilter } from "@/store/modes/walk-mode-slice";
import { selectPointCloudAnalyses } from "@/store/point-cloud-analysis-tool-selector";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { usePickingToolsCallbacks } from "@/tools/use-picking-tools-callbacks";
import { SceneFilter } from "@/types/scene-filter";
import { ExplorationControls } from "@faro-lotv/app-component-toolbox";
import { GUID, assert } from "@faro-lotv/foundation";
import {
  IElementImg360,
  isIElementGenericPointCloudStream,
  isSpaceAnnotation,
} from "@faro-lotv/ielement-types";
import { isEqual } from "lodash";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { selectIsElementViewablePointCloudStream } from "../mode-selectors";
import { OverviewBaseScene } from "./overview-scene-base";
import { useComputeValidOverviewScene } from "./overview-state";
import { OverviewTools } from "./overview-tools";
import { useUpdateOverviewCamera } from "./use-update-overview-camera";

/**
 * @returns The main Scene for the Overview Mode
 */
export function OverviewScene({
  initialState,
}: ModeSceneProps<SceneFilterLookAtInitialState>): JSX.Element | null {
  useDetectTooManyRedraws("OverviewScene");

  const dispatch = useAppDispatch();

  // Get data from store
  const { sheet, panos, paths, cad, annotations } = useCurrentScene();

  // Make sure the active element is valid for overview scene
  const main = useComputeValidOverviewScene();
  // TODO: refine criteria and constraints about when overview mode is triggered:
  // https://faro01.atlassian.net/browse/SWEB-3291
  assert(
    !!(main && isIElementGenericPointCloudStream(main)) || !!cad,
    "Overview Scene requires either a point cloud as the main object or an active CAD",
  );

  // Preload all needed data in parallel
  usePreload3DObjects([sheet, main, cad]);

  const isPointCloudViewable = useAppSelector(
    selectIsElementViewablePointCloudStream(main),
  );

  // Get handle on 3d data
  const pointCloud = useCached3DObjectIfExists(
    main && isIElementGenericPointCloudStream(main) && isPointCloudViewable
      ? main
      : null,
  );
  // cadModel will be Falsy until mesh has been loaded,
  const cadModel = useCached3DObjectIfReady(cad);
  const mainObject = pointCloud ?? cadModel;
  const sheetObject = useCached3DObjectIfExists(sheet);

  useStoreCameraWithCustomControls();

  useLayoutEffect(() => {
    if (initialState) {
      dispatch(setOverviewSceneFilter(initialState.scene));
    }
  }, [dispatch, initialState]);

  const showPanoInWalkMode = useCallback(
    (target?: IElementImg360) => {
      if (!target) return;
      dispatch(setActiveElement(target.id));
      dispatch(setWalkSceneFilter(SceneFilter.Pano));
      dispatch(changeMode("walk"));
    },
    [dispatch],
  );

  const { tools, onModelHovered, onModelClicked, onModelZoomed } =
    usePickingToolsCallbacks();

  const [isToolActive, setIsToolActive] = useState(false);

  // If there is neither an active cloud nor an active model, either loaded or loading, switch to sheet mode
  useLayoutEffect(() => {
    if (!pointCloud && !cad) {
      dispatch(changeMode("sheet"));
    }
  }, [dispatch, pointCloud, cad]);

  const [interactiveObjects, interactiveObjectsIds] = useInteractiveObjects(
    sheetObject,
    cadModel,
    pointCloud,
  );

  const measurements = useAppSelector(
    selectMeasurements(interactiveObjectsIds),
    isEqual,
  );

  const { cameraAnimation, target, resetCameraAnimation } =
    useUpdateOverviewCamera({
      sheet: sheetObject,
      pointCloud,
      cad: cadModel instanceof Error ? null : cadModel,
      panos,
      initialState,
    });

  const spaceAnnotations = useMemo(
    () => annotations.filter(isSpaceAnnotation),
    [annotations],
  );

  useRealtimeRaycasting(pointCloud, !isToolActive);

  const { clippingPlanes, setClippingPlanes } = useClippingPlanes();

  const allModelsBox = use3DmodelsBoundingBox(pointCloud, cadModel);

  const [clippingBoxChanging, setClippingBoxChanging] = useState(false);

  const analyses = useAppSelector(
    selectPointCloudAnalyses(pointCloud?.iElement.id),
    isEqual,
  );

  if (!pointCloud && !cadModel) {
    return null;
  }

  return (
    <>
      <OverviewBaseScene
        pointCloud={pointCloud}
        cadModel={cadModel instanceof Error ? null : cadModel}
        sheet={sheetObject}
        panos={panos}
        paths={paths}
        annotations={spaceAnnotations}
        measurements={measurements}
        isToolActive={isToolActive}
        clippingPlanes={clippingPlanes}
        renderOnDemand={!clippingBoxChanging && !cameraAnimation}
        onPlaceholderClick={showPanoInWalkMode}
        onModelHovered={onModelHovered}
        onModelClicked={onModelClicked}
        onModelZoomed={onModelZoomed}
        lookAtId={initialState?.lookAtId}
        analyses={analyses}
      />
      {!cameraAnimation && (
        <ExplorationControls
          target={target}
          enableClickToFocus={!isToolActive}
          obstacles={interactiveObjects}
        />
      )}
      {cameraAnimation && (
        <CameraAnimation
          {...cameraAnimation}
          onAnimationFinished={resetCameraAnimation}
        />
      )}
      {mainObject && (
        <OverviewTools
          ref={tools}
          activeModels={interactiveObjects}
          onToolActiveChanged={setIsToolActive}
          modelBox={allModelsBox}
          clippingPlanesChanged={setClippingPlanes}
          clippingBoxChanging={setClippingBoxChanging}
        />
      )}
    </>
  );
}

/**
 * @returns the objects the user can interact with in the overview scene (selected and not completely transparent)
 * @param sheet the current sheet object
 * @param cadModel the current cad model
 * @param pointCloud the current point cloud
 */
function useInteractiveObjects(
  sheet?: SheetObject | null,
  cadModel?: CadModelObject | Error | null,
  pointCloud?: PointCloudObject | null,
): [UnknownObject[], GUID[]] {
  const cadVisible = useTransparencySettingsContext(
    (state) => state.objectsOpacity.cadOpacity !== 0,
  );
  const pcVisible = useTransparencySettingsContext(
    (state) => state.objectsOpacity.pcOpacity !== 0,
  );
  const mapVisible = useTransparencySettingsContext(
    (state) => state.objectsOpacity.mapOpacity !== 0,
  );

  const cadSelected = useAppSelector(selectOverviewIsCadSelected);
  const cloudSelected = useAppSelector(selectOverviewIsPointCloudSelected);

  return useMemo(() => {
    const objects = [];
    if (mapVisible && sheet) {
      objects.push(sheet);
    }
    if (cloudSelected && pcVisible && pointCloud) {
      objects.push(pointCloud);
    }
    if (cadSelected && cadVisible && cadModel && !(cadModel instanceof Error)) {
      objects.push(cadModel);
    }
    return [objects, objects.map((o) => o.iElement.id)];
  }, [
    cadModel,
    cadSelected,
    cadVisible,
    cloudSelected,
    mapVisible,
    pcVisible,
    pointCloud,
    sheet,
  ]);
}
