import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { PointCloudSimpleSubscene } from "@/components/r3f/effects/point-cloud-simple-subscene";
import { CadRenderer } from "@/components/r3f/renderers/cad-renderer";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { centerOrthoCamera } from "@/hooks/use-center-camera-on-placeholders";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { useObjectVisibility } from "@/hooks/use-object-visibility";
import { useRealtimeRaycasting } from "@/hooks/use-real-time-raycasting";
import { useCached3DObject } from "@/object-cache";
import {
  selectCloudCrossSectionEnabled,
  selectCloudToCadAlignmentCloudElevation,
  selectCloudToCadAlignmentLayout,
  selectCloudToCadAlignmentModelElevation,
  selectIncrementalCloudTransform,
  selectModelCrossSectionEnabled,
} from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import { setCloudToCadIncrementalTransform } from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  blue,
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
  Map2DControls,
  quaternionToVector4Tuple,
  TomographicModelPass,
  TwoPointAlignment,
  UniformLights,
  useReproportionCamera,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericPointCloudStream,
  IElementModel3dStream,
} from "@faro-lotv/ielement-types";
import { assert } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box3, Plane, Quaternion, Vector3 } from "three";
import {
  useCadTomographicMaterial,
  useCameraInCurrentScene,
  useCenterCameraOnBoundingBoxIn2D,
  useClippingPlanesAtCloudElevation,
  useClippingPlanesAtModelElevation,
  useCloudForAlignmentView,
  useNewOrthographicCamera,
} from "../alignment-modes-commons/align-to-cad-utils";
import { RenderDispatch } from "../overview-mode/render-dispatch";

type AlignCloudToCadOverlayScreenProps = {
  /** The active sheet to be aligned to the CAD model */
  activeCloud: IElementGenericPointCloudStream;

  /** The active cad model for alignment */
  activeCad: IElementModel3dStream;

  /** The bounding box of the cad model in world */
  cadBox: Box3;
};

/** @returns the overlay scene for align the cloud to cad in 2d */
export function AlignCloudToCadOverlayScreen({
  activeCad,
  activeCloud,
  cadBox,
}: AlignCloudToCadOverlayScreenProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [controlsEnabled, setControlsEnabled] = useState(true);

  const cadModel = useCached3DObject(activeCad);
  const cloudToAlign = useCached3DObject(activeCloud);

  const cloudBox = useObjectBoundingBox(cloudToAlign, activeCloud.id);
  assert(cloudBox, "unable to compute bounding box for cloud");

  const boundingBox = useMemo(
    () => cloudBox.clone().union(cadBox),
    [cadBox, cloudBox],
  );

  useObjectVisibility(cadModel, true);

  const incrementalTransform = useAppSelector(selectIncrementalCloudTransform);
  // TwoPointAlignment apply this transform to the cloud, so it
  // can be aligned to the cad in the view
  const [transform] = useState<AlignmentTransform>(
    incrementalTransform ?? {
      position: [0, 0, 0],
      quaternion: [0, 0, 0, 1],
      scale: [1, 1, 1],
    },
  );

  const camera = useNewOrthographicCamera();
  useCameraInCurrentScene(camera);

  const size = useThree((s) => s.size);
  const centeringData = useCenterCameraOnBoundingBoxIn2D(
    boundingBox,
    size.width / size.height,
  );
  const [initialCenteringData] = useState(() => centeringData);
  useEffect(() => {
    centerOrthoCamera(camera, initialCenteringData);
  }, [camera, initialCenteringData]);

  useReproportionCamera(camera);
  useCadTomographicMaterial(cadModel);

  const onTransformChanged = useCallback(
    (position: Vector3, quaternion: Quaternion, scale: Vector3) => {
      const transform: AlignmentTransform = {
        scale: scale.toArray(),
        position: position.toArray(),
        quaternion: quaternionToVector4Tuple(quaternion),
      };

      dispatch(setCloudToCadIncrementalTransform(transform));
    },
    [dispatch],
  );

  const modelElevation = useAppSelector(
    selectCloudToCadAlignmentModelElevation,
  );
  const cloudElevation = useAppSelector(
    selectCloudToCadAlignmentCloudElevation,
  );

  const isModelCrossSectionEnabled = useAppSelector(
    selectModelCrossSectionEnabled,
  );
  const isCloudCrossSectionEnabled = useAppSelector(
    selectCloudCrossSectionEnabled,
  );

  const modelClippingPlanes = useClippingPlanesAtModelElevation(
    cadModel,
    modelElevation,
    isModelCrossSectionEnabled,
  );
  const computedCloudPlanes = useClippingPlanesAtCloudElevation(
    cloudElevation,
    isCloudCrossSectionEnabled,
  );

  // this segment of the code address re-rendering to apply clipping planes after switch of layout
  const alignmentLayout = useAppSelector(selectCloudToCadAlignmentLayout);
  const [cloudClippingPlanes, setCloudClippingPlanes] = useState<Plane[]>([]);
  useEffect(() => {
    setCloudClippingPlanes(computedCloudPlanes);
  }, [computedCloudPlanes, alignmentLayout]);

  useRealtimeRaycasting(cloudToAlign, false);

  useCloudForAlignmentView(cloudToAlign, cloudClippingPlanes);

  return (
    <>
      <UniformLights />
      <CadRenderer
        cadModel={cadModel}
        clippingPlanes={modelClippingPlanes}
        clippingInLocalTransform
      />
      <TwoPointAlignment
        onPointerDown={() => setControlsEnabled(false)}
        onPointerUp={() => setControlsEnabled(true)}
        onTransformChanged={onTransformChanged}
        isScaleEnabled={false}
        disableDataSquash={cloudClippingPlanes.length > 0}
        {...transform}
      >
        <PointCloudRenderer pointCloud={cloudToAlign} />
      </TwoPointAlignment>
      <EffectPipelineWithSubScenes>
        <PointCloudSimpleSubscene pointCloud={cloudToAlign} />
        <TomographicModelPass
          filter={(o) => o.userData.type === RenderDispatch.CadModel}
          camera={camera}
          bias={0.05}
          tomographicColor={blue[600]}
        />
        <FilteredRenderPass
          filter={(o) =>
            !isObjPointCloudPoint(o) &&
            o.userData.type !== RenderDispatch.CadModel
          }
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipelineWithSubScenes>
      <Map2DControls
        camera={camera}
        referencePlaneHeight={modelElevation}
        enabled={controlsEnabled}
        isPrimaryControl={false}
      />
    </>
  );
}
