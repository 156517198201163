import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import { centerOrthoCamera } from "@/hooks/use-center-camera-on-placeholders";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { PointCloudObject, SheetObject } from "@/object-cache";
import {
  selectCrossSectionEnabled,
  selectIncrementalSheetTransform,
  selectSheetToCloudAlignmentSheetElevation,
} from "@/store/modes/sheet-to-cloud-alignment-mode-selectors";
import { setSheetToCloudIncrementalTransform } from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  CopyToScreenPass,
  EffectPipeline,
  FilteredRenderPass,
  Map2DControls,
  quaternionToVector4Tuple,
  TwoPointAlignment,
  UniformLights,
  useReproportionCamera,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Quaternion, Vector3 } from "three";
import {
  useCameraInCurrentScene,
  useCenterCameraOnBoundingBoxIn2D,
  useClippingPlanesAtCloudElevation,
  useCloudForAlignmentView,
  useNewOrthographicCamera,
} from "../alignment-modes-commons/align-to-cad-utils";

type AlignSheetToCloudOverlaySceneProps = {
  /** The sheet to be aligned to the reference cloud */
  sheetObject: SheetObject;

  /** The reference cloud object for alignment */
  cloudObject: PointCloudObject;
};

/** @returns the overlay scene for align sheet to cloud in 2d */
export function AlignSheetToCloudOverlayScene({
  sheetObject,
  cloudObject,
}: AlignSheetToCloudOverlaySceneProps): JSX.Element {
  const dispatch = useAppDispatch();

  const camera = useNewOrthographicCamera();
  useCameraInCurrentScene(camera);

  const cloudBox = useObjectBoundingBox(cloudObject, cloudObject.iElement.id);
  const sheetBox = useObjectBoundingBox(sheetObject, sheetObject.iElement.id);
  assert(
    cloudBox && sheetBox,
    "unable to compute the combined cloud and sheet bounding box",
  );

  const boundingBox = useMemo(
    () => cloudBox.clone().union(sheetBox),
    [cloudBox, sheetBox],
  );

  const size = useThree((s) => s.size);
  const centeringData = useCenterCameraOnBoundingBoxIn2D(
    boundingBox,
    size.width / size.height,
  );

  const [initialCenteringData] = useState(() => centeringData);
  useEffect(() => {
    centerOrthoCamera(camera, initialCenteringData);
  }, [camera, initialCenteringData]);

  useReproportionCamera(camera);

  const [controlsEnabled, setControlsEnabled] = useState(true);

  const incrementalTransform = useAppSelector(selectIncrementalSheetTransform);

  // TwoPointAlignment apply this transform to the sheet, so it
  // can be aligned to the cloud in the view
  const [transform] = useState<AlignmentTransform>(
    incrementalTransform ?? {
      position: [0, 0, 0],
      quaternion: [0, 0, 0, 1],
      scale: [1, 1, 1],
    },
  );

  const onTransformChanged = useCallback(
    (position: Vector3, quaternion: Quaternion, scale: Vector3) => {
      const transform: AlignmentTransform = {
        scale: scale.toArray(),
        position: position.toArray(),
        quaternion: quaternionToVector4Tuple(quaternion),
      };

      dispatch(setSheetToCloudIncrementalTransform(transform));
    },
    [dispatch],
  );

  const sheetElevation = useAppSelector(
    selectSheetToCloudAlignmentSheetElevation,
  );

  const isCrossSectionEnabled = useAppSelector(selectCrossSectionEnabled);

  const cloudClippingPlanes = useClippingPlanesAtCloudElevation(
    sheetElevation,
    isCrossSectionEnabled,
  );

  useCloudForAlignmentView(cloudObject, cloudClippingPlanes);

  return (
    <>
      <UniformLights />

      <PointCloudRenderer pointCloud={cloudObject} />
      <TwoPointAlignment
        onPointerDown={() => setControlsEnabled(false)}
        onPointerUp={() => setControlsEnabled(true)}
        onTransformChanged={onTransformChanged}
        {...transform}
      >
        <SheetRenderer sheet={sheetObject} />
      </TwoPointAlignment>
      <EffectPipeline>
        <FilteredRenderPass
          filter={(o) =>
            isObjPointCloudPoint(o) || o.name === sheetObject.iElement.id
          }
        />
        <FilteredRenderPass
          filter={(o) =>
            !isObjPointCloudPoint(o) && o.name !== sheetObject.iElement.id
          }
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipeline>
      <Map2DControls
        camera={camera}
        referencePlaneHeight={sheetElevation}
        enabled={controlsEnabled}
        isPrimaryControl={false}
      />
    </>
  );
}
