import {
  CancelAnnotationCreationEventProperties,
  CreateAnnotationEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { useUiOverlayContext } from "@/components/common/ui-overlay-provider";
import {
  CreateAnnotationForm,
  CreateAnnotationFormProps,
} from "@/components/ui/annotations/create-annotation-form";
import {
  UnknownObject,
  isFromSheetObject,
  isPanoObject,
} from "@/object-cache-type-guard";
import { setHasCreatedNewAnnotation } from "@/store/create-annotation-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { deactivateTool } from "@/store/ui/ui-slice";
import {
  CreateRectangleAnnotation,
  useOverrideCursor,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { IElementTypeHint } from "@faro-lotv/ielement-types";
import { Dialog } from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Mesh } from "three";
import { PointAnnotationData } from "../annotation-mutation-utils";
import { ToolCallback, ToolControlsRef } from "../tool-controls-interface";
import { useCreateModel3dAnnotation } from "./use-create-model-3d-annotation";
import { useCreateSinglePointAnnotation } from "./use-create-single-point-annotation";

type AnnotationToCreate = PointAnnotationData | Mesh;

type AnnotationPickerProps = {
  /** True if this tool is the active one */
  isActive: boolean;

  /** List of active models in the scene that will send events to the tool */
  activeModels: UnknownObject[] | null;
};

/** @returns the tool to create new annotations */
export const AnnotationPicker = forwardRef<
  ToolControlsRef,
  AnnotationPickerProps
>(function AnnotationPicker(
  { isActive, activeModels },
  ref,
): JSX.Element | null {
  const dispatch = useAppDispatch();

  // Reset flag each time the component is enabled
  useEffect(() => {
    if (isActive) {
      dispatch(setHasCreatedNewAnnotation(false));
    }
  }, [dispatch, isActive]);

  const [annotation, setAnnotation] = useState<AnnotationToCreate>();
  useOverrideCursor("crosshair", isActive && !annotation);

  const [isUserDragging, setIsUserDragging] = useState(false);
  const pointClicked = useCallback<ToolCallback>(
    (ev, iElementId) => {
      if (isUserDragging) return;
      ev.stopPropagation();

      Analytics.track<CreateAnnotationEventProperties>(
        EventType.createAnnotation,
        { shape: "point" },
      );

      setAnnotation({
        points: [ev.point.toArray()],
        parentId: iElementId,
        type: isFromSheetObject(ev.object)
          ? IElementTypeHint.mapAnnotation
          : IElementTypeHint.spaceAnnotation,
      });
    },
    [isUserDragging],
  );

  useImperativeHandle(ref, () => ({
    pointClicked,
  }));

  const pano = activeModels?.find(isPanoObject);

  const createSinglePointAnnotation = useCreateSinglePointAnnotation();
  const createModel3dAnnotation = useCreateModel3dAnnotation();

  const saveAnnotation = useCallback<CreateAnnotationFormProps["onSave"]>(
    async (details) => {
      assert(annotation, "Unable to save an undefined annotation");

      if (annotation instanceof Mesh) {
        assert(pano, "A pano is required to create a Model3D Annotation");
        await createModel3dAnnotation(pano.iElement, annotation, details);
      } else {
        await createSinglePointAnnotation(annotation, details);
      }

      dispatch(setHasCreatedNewAnnotation(true));
      setAnnotation(undefined);
      dispatch(deactivateTool());
    },
    [
      annotation,
      createModel3dAnnotation,
      createSinglePointAnnotation,
      dispatch,
      pano,
    ],
  );

  const closeDialog = useCallback(() => setAnnotation(undefined), []);
  const { setContent } = useUiOverlayContext();
  useEffect(() => {
    setContent(
      annotation ? (
        <Dialog
          open
          PaperProps={{
            sx: { p: 1, background: "transparent" },
            elevation: 0,
          }}
        >
          <CreateAnnotationForm onClose={closeDialog} onSave={saveAnnotation} />
        </Dialog>
      ) : undefined,
    );

    return () => setContent(undefined);
  }, [annotation, closeDialog, saveAnnotation, setContent]);

  return (
    <>
      {isActive && pano && (
        <CreateRectangleAnnotation
          onDragStart={() => setIsUserDragging(true)}
          onDragEnd={() => setIsUserDragging(false)}
          onCreated={(annotation) => {
            Analytics.track<CreateAnnotationEventProperties>(
              EventType.createAnnotation,
              { shape: "rectangle" },
            );
            setAnnotation(annotation);
          }}
          onCancelled={() =>
            Analytics.track<CancelAnnotationCreationEventProperties>(
              EventType.cancelAnnotationCreation,
              { via: "escape key" },
            )
          }
        />
      )}
      {annotation instanceof Mesh && <primitive object={annotation} />}
    </>
  );
});
