import {
  MeasurementUnits,
  MeasurementUnitsInfo,
  isMeasurementUnit,
  labelToMeters,
  metersToLabel,
  validateMeasurementLabel,
} from "@faro-lotv/app-component-toolbox";
import { Dropdown, TextField } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";
import {
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

/** The options for measurement units dropdown */
const MeasurementUnitsMetric = Object.entries(MeasurementUnits).filter(
  (u) => MeasurementUnitsInfo[u[1]].type === "metric",
);
const MeasurementUnitsImperial = Object.entries(MeasurementUnits).filter(
  (u) => MeasurementUnitsInfo[u[1]].type === "us",
);
const MeasurementUnitsOptions = [
  {
    key: "us",
    value: "imperial",
    isHeader: true,
  },
  ...MeasurementUnitsImperial.map(([key, value]) => ({
    key,
    value,
  })),
  {
    key: "metric",
    value: "metric",
    isHeader: true,
  },
  ...MeasurementUnitsMetric.map(([key, value]) => ({
    key,
    value,
  })),
];

export type MeasurementInputActions = {
  /** Call the onAccepted hook with the latest valid values inserted by the user */
  acceptMeasurement(): void;
};

interface Props {
  /** Measurement value */
  distanceInMeters: number;

  /** Unit of measurement */
  unit: MeasurementUnits;

  /** Callback executed every time the measurement changes */
  onChange(distanceInMeters: number): void;

  /** Callback executed when the user accept the measurement */
  onAccepted(distanceInMeters: number, unit: MeasurementUnits): void;

  /** A handle on the action objects to modify the TwoPointAlignments */
  actions: RefObject<MeasurementInputActions>;

  /** A callback triggered every time the user input a new value */
  onValueChanged(): void;

  /** A callback triggered every time the user select a new unit */
  onUnitChanged(unit: MeasurementUnits): void;
}

/**
 * @returns a combination of input field and a select dropdown for measurement values
 */
export function MeasurementInput({
  distanceInMeters,
  unit,
  onChange,
  onAccepted,
  onValueChanged,
  onUnitChanged,
  actions,
}: Props): JSX.Element {
  const [distance, setDistance] = useState(
    metersToLabel(distanceInMeters, unit),
  );

  const [currentUnit, setCurrentUnit] = useState(unit);

  const validationError = useMemo(() => {
    const isValid = validateMeasurementLabel(distance, currentUnit);
    if (isValid) return undefined;
    return `Invalid syntax for ${currentUnit}`;
  }, [distance, currentUnit]);

  useEffect(() => {
    if (!validationError) {
      const meters = labelToMeters(distance, currentUnit);
      if (meters !== undefined) {
        onChange(meters);
      }
    }
  }, [currentUnit, distance, onChange, validationError]);

  const acceptMeasurement = useCallback((): void => {
    if (!validationError) {
      const meters = labelToMeters(distance, currentUnit);
      if (meters !== undefined) {
        onAccepted(meters, currentUnit);
      }
    }
  }, [distance, onAccepted, currentUnit, validationError]);

  useImperativeHandle(actions, () => ({ acceptMeasurement }));

  return (
    <Stack direction="row" alignItems="flex-start">
      <TextField
        controlStyle={{ width: 400 }}
        error={validationError}
        placeholder="Enter measurement"
        text={distance.toString()}
        onTextChanged={(text) => {
          setDistance(text);
        }}
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        }}
        inputProps={{
          onKeyDown: (ev) => {
            if (ev.key === "Enter") {
              acceptMeasurement();
            }
          },
          onBlur: onValueChanged,
        }}
      />
      <Dropdown
        options={MeasurementUnitsOptions}
        onChange={(e) => {
          const unit = isMeasurementUnit(e.target.value)
            ? e.target.value
            : MeasurementUnits.meters;
          onUnitChanged(unit);
          setCurrentUnit(unit);
        }}
        value={currentUnit}
        fullWidth
        sx={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      />
    </Stack>
  );
}
