import { clearStore } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/ielement-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/** The type of reference plane used for an analysis */
export enum ReferencePlaneType {
  /** Reference plane is computed by best-fit of the selected points */
  flatness = "flatness",

  /** Reference plane is set to a level plane at a fixed elevation */
  elevation = "elevation",

  /** Reference plane is set to a level plane at elevation of the bestfit center */
  levelness = "levelness",
}

export type PointCloudAnalysis = {
  /** The unique id of this analysis */
  id: GUID;

  /** The polygon used to select points for analysis */
  polygonSelection: Vector3Tuple[];

  /** Tolerance (+/-) value for the analysis */
  tolerance: number;

  /** The id of the point cloud iElement this analysis originated from */
  parentId: GUID;

  /** The type of reference plane used for this analysis */
  referencePlaneType: ReferencePlaneType;

  /** The elevation for the level reference plane */
  elevation?: number;

  /** Show reference plane when analysis is active */
  showReferencePlane?: boolean;
};

type PointCloudAnalysisToolState = {
  /** For each specific point cloud, identified by its GUID, store the list of analyses associated to it */
  analyses: Record<GUID, PointCloudAnalysis[]>;

  /** The active analysis or undefined if none*/
  activeAnalysis?: PointCloudAnalysis;

  /** True if an analysis is being created */
  isAnalysisBeingCreated: boolean;

  /** Number of analysis created this session */
  numberOfSessionAnalyses: number;
};

const initialState: PointCloudAnalysisToolState = {
  analyses: {},
  activeAnalysis: undefined,
  isAnalysisBeingCreated: false,
  numberOfSessionAnalyses: 0,
};

const pointCloudAnalysisToolSlice = createSlice({
  name: "pointCloudAnalysisTool",
  initialState,
  reducers: {
    addAnalysis(
      state,
      action: PayloadAction<{
        pointCloudID: GUID;
        analysis: PointCloudAnalysis;
      }>,
    ) {
      state.numberOfSessionAnalyses++;
      if (!(action.payload.pointCloudID in state.analyses)) {
        state.analyses[action.payload.pointCloudID] = [];
      }

      state.analyses[action.payload.pointCloudID].push(action.payload.analysis);
    },
    removeAnalysis(state, action: PayloadAction<GUID>) {
      const analysis = findAnalysis(state.analyses, action.payload);
      if (analysis) {
        if (state.activeAnalysis?.id === analysis.id) {
          state.activeAnalysis = undefined;
        }
        const analyses = state.analyses[analysis.parentId];
        state.analyses[analysis.parentId] = analyses.filter(
          (a) => a.id !== action.payload,
        );
      }
    },
    setAnalysisTolerance(
      state,
      action: PayloadAction<{ analysisId: GUID; tolerance: number }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.tolerance = action.payload.tolerance;
      }
    },
    setActiveAnalysis(
      state,
      action: PayloadAction<PointCloudAnalysis | undefined>,
    ) {
      state.activeAnalysis = action.payload;
    },
    setIsAnalysisBeingCreated(state, action: PayloadAction<boolean>) {
      state.isAnalysisBeingCreated = action.payload;
    },
    setAnalysisReferencePlaneType(
      state,
      action: PayloadAction<{
        analysisId: GUID;
        referencePlaneType: ReferencePlaneType;
      }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.referencePlaneType = action.payload.referencePlaneType;
      }
    },
    setAnalysisElevation(
      state,
      action: PayloadAction<{ analysisId: GUID; elevation: number }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.elevation = action.payload.elevation;
      }
    },
    setAnalysisShowReferencePlane(
      state,
      action: PayloadAction<{ analysisId: GUID; showReferencePlane: boolean }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.showReferencePlane = action.payload.showReferencePlane;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const pointCloudAnalysisToolReducer =
  pointCloudAnalysisToolSlice.reducer;

export const {
  addAnalysis,
  removeAnalysis,
  setAnalysisTolerance,
  setActiveAnalysis,
  setIsAnalysisBeingCreated,
  setAnalysisReferencePlaneType,
  setAnalysisElevation,
  setAnalysisShowReferencePlane,
} = pointCloudAnalysisToolSlice.actions;

/**
 *  @returns the analysis matching a given id
 *  @param analyses List of analyses per point cloud
 *  @param analysisId The id of the analysis to get
 */
export function findAnalysis(
  analyses: Record<GUID, PointCloudAnalysis[]>,
  analysisId: GUID,
): PointCloudAnalysis | undefined {
  for (const listOfAnalyses of Object.values(analyses)) {
    const analysis = listOfAnalyses.find((a) => a.id === analysisId);
    if (analysis) {
      return analysis;
    }
  }
}
