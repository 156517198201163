import {
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { FaroStep } from "@faro-lotv/flat-ui";
import { useMemo } from "react";
import { AlignmentStepper } from "../alignment-modes-commons/alignment-stepper";

interface AlignWizardProgressBarProps {
  /** A callback that is called when the user clicks "Next" button in the progressbar */
  goToNextStep(): void;
}

/**
 * @returns The progress bar that is shown at the top of an alignment Wizard mode
 *  Allows user to see where there are currently in the alignment process
 */
export function AlignWizardProgressBar({
  goToNextStep,
}: AlignWizardProgressBarProps): JSX.Element {
  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const referenceElementId = useAppSelector(selectWizardReferenceElementId);

  const steps: FaroStep[] = useMemo(
    () => [
      {
        key: "1",
        label: "Select reference",
        allowNext: () => !!elementToAlignId && !!referenceElementId,
      },
    ],
    [elementToAlignId, referenceElementId],
  );

  return (
    <AlignmentStepper
      steps={steps}
      lastStepButtonText="Align"
      hideStepNumbers
      onLastStepButtonClicked={goToNextStep}
    />
  );
}
