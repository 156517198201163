import {
  EventType,
  SelectLocationProperties,
} from "@/analytics/analytics-events";
import { AnnotationsRenderer } from "@/components/r3f/renderers/annotations/annotations-renderer";
import { CadRenderer } from "@/components/r3f/renderers/cad-renderer";
import { MeasurementsRenderer } from "@/components/r3f/renderers/measurements/measurements-renderer";
import { WayPointTarget } from "@/components/r3f/renderers/odometry-paths/walk-paths-renderer";
import { use3DmodelsBoundingBox } from "@/hooks/use-object-bounding-box";
import { useSceneContextMenuEventHandlers } from "@/hooks/use-scene-context-menu-event-handlers";
import { useViewIfNeeded } from "@/hooks/use-view-if-needed";
import { CadModelObject, PointCloudObject } from "@/object-cache";
import { isPointCloudObject } from "@/object-cache-type-guard";
import { Measurement } from "@/store/measurement-tool-slice";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { PickingToolsRef } from "@/tools/picking-tools";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  GUID,
  IElementGenericAnnotation,
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { isCadModel } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { Box3, Plane, Vector3 } from "three";
import { WalkPlaceholders } from "../walk-placeholders";
import { WalkPointCloud } from "../walk-pointcloud";
import { ViewType } from "../walk-types";
import { SplitscreenModePipeline } from "./spitscreen-mode-pipeline";
import { useOverlayToolsVisibility } from "./use-overlay-tools-visibility";
import { WalkModePipeline } from "./walk-mode-pipeline";

type WalkModelsStaticSceneProps = PickingToolsRef & {
  /** The main object to render in the scene */
  mainModel: PointCloudObject | CadModelObject | null;

  /** The overlay model to render on top of the cad model */
  overlayModel: CadModelObject | null;

  /** The floorplan where the placehodlers are placed */
  sheet?: IElementGenericImgSheet;

  /** List of all the placeholders to show */
  placeholders: IElementImg360[];

  /** If true other panorama's placeholders will be rendered  */
  shouldShowPlaceholders: boolean;

  /** If true, annotations will be rendered  */
  shouldShowAnnotations: boolean;

  /** True if there's an active tool */
  isToolActive: boolean;

  /** List of all the annotations for this area */
  annotations: IElementGenericAnnotation[];

  /** List of all the measurements in the store for the current selection */
  measurements: Measurement[];

  /** List of placeholder IDs to hide from the placeholders list */
  hiddenPlaceholders: GUID[];

  /** Optional ID of the annotation to look at */
  lookAtId?: GUID;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Whether the 3d models should be rendered on demand */
  renderOnDemand: boolean;

  /** Callback executed when the user walks to a new position in the scene */
  onUserWalkedTo(p: Vector3): void;

  /** Callback called when the active waypoint target element inside the walk scene has changed. */
  onWayPointChanged(target: WayPointTarget): void;

  /** Optional callback to signal outside that the bounding box of all models has changed. */
  onAllModelsBoxChanged?(box: Box3): void;

  /** Which view is this: main view in walk mode, left view, or right view in splitscreen mode */
  viewType: ViewType;
};

/** @returns The scene rendering a point cloud, a CAD model, or both */
export function WalkModelsStaticScene({
  mainModel,
  overlayModel,
  placeholders,
  shouldShowPlaceholders,
  shouldShowAnnotations,
  isToolActive,
  annotations,
  measurements,
  sheet,
  hiddenPlaceholders,
  viewType,
  lookAtId,
  clippingPlanes,
  renderOnDemand,
  onUserWalkedTo,
  onModelClicked,
  onModelHovered,
  onModelZoomed,
  onWayPointChanged,
  onAllModelsBoxChanged,
}: WalkModelsStaticSceneProps): JSX.Element {
  useOverlayToolsVisibility(mainModel, overlayModel);

  const sourcePc = isPointCloudObject(mainModel) ? mainModel : null;
  const pcToRender = useViewIfNeeded(sourcePc, viewType === ViewType.RightView);
  const cadToRender = isCadModel(mainModel) ? mainModel : overlayModel;
  const camera = useThree((s) => s.camera);

  const allModelsBox = use3DmodelsBoundingBox(sourcePc, cadToRender);

  const activeTool = useAppSelector(selectActiveTool);

  useSceneContextMenuEventHandlers({
    pointCloud: pcToRender ?? undefined,
    cadModel: cadToRender ?? undefined,
  });

  useEffect(() => {
    onAllModelsBoxChanged?.(allModelsBox);
  }, [allModelsBox, onAllModelsBoxChanged]);

  return (
    <>
      {pcToRender && (
        <WalkPointCloud
          pointCloud={pcToRender}
          clippingPlanes={clippingPlanes}
          visible
          raycastEnabled={activeTool !== ToolName.clipScene}
          onFloorClicked={onUserWalkedTo}
          onPointHovered={(ev) => onModelHovered(ev, pcToRender.iElement.id)}
          onPointClicked={(ev) => onModelClicked(ev, pcToRender.iElement.id)}
          onPointZoomed={(ev) => onModelZoomed(ev, pcToRender.iElement.id)}
        />
      )}
      {cadToRender && (
        <CadRenderer
          cadModel={cadToRender}
          raycastEnabled={activeTool !== ToolName.clipScene}
          onPointerMove={(ev) => onModelHovered(ev, cadToRender.iElement.id)}
          onClick={(ev) => onModelClicked(ev, cadToRender.iElement.id)}
          onWheel={(ev) => onModelZoomed(ev, cadToRender.iElement.id)}
          clippingPlanes={clippingPlanes}
        />
      )}
      {shouldShowPlaceholders && (
        <WalkPlaceholders
          placeholders={placeholders}
          sheet={sheet}
          hiddenPlaceholders={hiddenPlaceholders}
          onPlaceholderClick={(targetElement, position) => {
            Analytics.track(EventType.selectLocation, {
              via: SelectLocationProperties.panoView,
            });

            onUserWalkedTo(position);

            onWayPointChanged({
              targetElement,
              position,
              quaternion: camera.quaternion,
            });
          }}
        />
      )}
      {shouldShowAnnotations && (
        <AnnotationsRenderer annotations={annotations} lookAtId={lookAtId} />
      )}
      <MeasurementsRenderer
        measurements={measurements}
        isToolActive={isToolActive}
      />
      {/*
       * The <ComposeFramebuffersPass> component has issues when used in splitscreen mode.
       * Somehow, the depth of the left view is not cleared correctly. That component
       * is not needed anyway in splitscreen mode, therefore a distinct effect pipeline
       * is instantiated according to the mode being walk or splitscreen.
       */}
      {viewType === ViewType.MainView ? (
        <WalkModePipeline
          enabled
          pointCloud={pcToRender}
          cadModel={cadToRender}
          renderOnDemand={renderOnDemand}
        />
      ) : (
        <SplitscreenModePipeline
          enabled
          pointCloud={pcToRender}
          cadModel={cadToRender}
        />
      )}
    </>
  );
}
