import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { useObjectView } from "@/hooks/use-object-view";
import { useCached3DObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
} from "@faro-lotv/app-component-toolbox";
import { blue } from "@faro-lotv/flat-ui";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";
import {
  assert,
  memberWithPrivateData,
  SupportedCamera,
} from "@faro-lotv/lotv";
import {
  CachedWorldTransform,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";
import { OrthographicCamera } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect, useMemo } from "react";
import {
  Euler,
  Group,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Plane,
  PlaneGeometry,
  OrthographicCamera as ThreeOrthographicCamera,
  Vector3,
} from "three";
import { useCurrentScene } from "../mode-data-context";
import { useClippingBoxContext } from "./clipping-box-mode-context";
import { planesToArray } from "./planes-to-array";

/** Extra padding(m) added to the camera framing */
const CAMERA_MARGIN = 0.1;

type OverviewImagePreviewProps = {
  /** Flag to show the helper plane */
  showBackgroundPlane: boolean;

  /**
   * Callback to be called when the aspect ratio of the object changes.
   * For example, when the clipping box changes
   */
  onAspectRatioChanged?(aspectRatio: number): void;
};

/**
 * This component is used to render a top-down view of the pointcloud in the scene.
 * The object is framed by the camera and the clipping box is applied.
 *
 * @returns a top-down view of the pointcloud in the scene
 */
export function OverviewImagePreview({
  showBackgroundPlane,
  onAspectRatioChanged,
}: OverviewImagePreviewProps): JSX.Element {
  const { size, camera } = useThree();
  const { main } = useCurrentScene();
  assert(
    main && isIElementPointCloudStream(main),
    "The overview image preview requires a point cloud",
  );

  const transform = useAppSelector(selectIElementWorldTransform(main.id));
  const pointCloud = useCached3DObject(main);

  // Create a view of the pointcloud so that it can be shown both in the main scene and the overview
  const view = useObjectView(pointCloud);

  // Object used so that the objects created in the component are properly disposed
  const utils = useMemo(() => new OverviewImagePreviewUtils(), []);

  const { clippingPlanes } = useClippingBoxContext();

  // Clipping planes correctly positioned in the scene
  const pcClippingPlanes = useMemo(() => {
    if (!clippingPlanes) return;
    return utils.createClippingPlanes(clippingPlanes, transform);
  }, [clippingPlanes, transform, utils]);

  // Group used to properly position the helper plane
  const group = useMemo(() => new Group(), []);

  // Update the camera position and orientation to frame the pointcloud
  useEffect(() => {
    if (!pcClippingPlanes) return;
    // From the clipping planes, get a matrix that represents the clipping box of the pointcloud
    const matrix = utils.createClippingPlanesMatrix(pcClippingPlanes);

    utils.frameCameraOnPointCloud(camera, matrix, size, onAspectRatioChanged);

    // Update the group position, rotation and scale based on the clipping box matrix
    matrix.decompose(group.position, group.quaternion, group.scale);
  }, [camera, group, onAspectRatioChanged, pcClippingPlanes, size, utils]);

  // This is an helper for the user to understand the real size of the overview image
  const planeHelper = useMemo(
    () =>
      new Mesh(
        new PlaneGeometry(1, 1),
        new MeshBasicMaterial({
          transparent: true,
          opacity: 0.3,
          color: blue[100],
        }),
      ),
    [],
  );

  return (
    <>
      <primitive object={group}>
        <primitive
          object={planeHelper}
          quaternion={[-Math.SQRT1_2, 0, 0, Math.SQRT1_2]}
          position={[0.5, 0, 0.5]}
          visible={showBackgroundPlane}
        />
      </primitive>
      <OrthographicCamera makeDefault />
      <PointCloudRenderer pointCloud={view} clippingPlanes={pcClippingPlanes} />
      <EffectPipelineWithSubScenes>
        <PointCloudSubscene pointCloud={view} />
        <FilteredRenderPass
          filter={(obj: Object3D) => !isObjPointCloudPoint(obj)}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipelineWithSubScenes>
    </>
  );
}

/** Class used to ensure that the allocated objects are removed when the component is unmounted */
class OverviewImagePreviewUtils {
  createClippingPlanes = memberWithPrivateData(() => {
    const mat = new Matrix4();
    const planes = [
      new Plane(),
      new Plane(),
      new Plane(),
      new Plane(),
      new Plane(),
      new Plane(),
    ];

    return (clippingPlanes: Plane[], transform: CachedWorldTransform) => {
      mat.fromArray(transform.worldMatrix);

      planes.forEach((plane, i) => {
        plane.copy(clippingPlanes[i]);
        plane.applyMatrix4(mat);
      });

      return [...planes];
    };
  });

  createClippingPlanesMatrix = memberWithPrivateData(() => {
    const mat = new Matrix4();
    return (clippingPlanes: Plane[]) =>
      // The generated matrix from the function is row-major because it is used by the backend,
      // three.js uses column-major matrices, so the matrix is transposed
      mat.fromArray(planesToArray(clippingPlanes)).transpose();
  });

  frameCameraOnPointCloud = memberWithPrivateData(() => {
    const xAxis = new Vector3();
    const yAxis = new Vector3();
    const zAxis = new Vector3();
    const center = new Vector3();
    const offset = new Vector3();
    const euler = new Euler();
    const mat1 = new Matrix4();
    const mat2 = new Matrix4();
    const mat3 = new Matrix4();

    return (
      camera: SupportedCamera,
      matrix: Matrix4,
      size: { width: number; height: number },
      onAspectRatioChanged?: (aspectRatio: number) => void,
    ) => {
      // First three columns of the matrix are the rotation with the scale
      // But they also are the three directions of the sides of the box
      matrix.extractBasis(xAxis, yAxis, zAxis);

      const xSize = xAxis.length();
      const ySize = yAxis.length();
      const zSize = zAxis.length();

      onAspectRatioChanged?.(xSize / zSize);

      // The matrix's position is a bottom corner of the box
      // By adding to it half of each side, we get the center of the box
      center
        .setFromMatrixPosition(matrix)
        .add(xAxis.multiplyScalar(0.5))
        .add(yAxis.multiplyScalar(0.5))
        .add(zAxis.multiplyScalar(0.5));

      // Place the camera above the center of the box
      camera.position
        .copy(center)
        .add(offset.set(0, ySize / 2 + CAMERA_MARGIN, 0));
      // Make the camera look down at the center of the box
      camera.lookAt(center);

      euler.setFromRotationMatrix(matrix, "YZX");
      const pos = camera.position;

      mat1.makeTranslation(pos.x, pos.y, pos.z);
      mat2.makeRotationY(euler.y);
      mat3.makeTranslation(-pos.x, -pos.y, -pos.z);
      const mat4 = mat1.multiply(mat2).multiply(mat3);

      camera.applyMatrix4(mat4);

      // The camera is centered so that the entire clipping box is always visible
      if (camera instanceof ThreeOrthographicCamera) {
        const aspectRatio = size.width / size.height;
        if (xSize / aspectRatio > zSize) {
          camera.right = xSize / 2 + CAMERA_MARGIN;
          camera.left = -camera.right;
          camera.top = camera.right / aspectRatio;
          camera.bottom = -camera.top;
        } else {
          camera.top = zSize / 2 + CAMERA_MARGIN;
          camera.bottom = -camera.top;
          camera.right = camera.top * aspectRatio;
          camera.left = -camera.right;
        }
        camera.near = CAMERA_MARGIN;
        camera.far = ySize + 2 * CAMERA_MARGIN;
      }

      camera.updateProjectionMatrix();
    };
  });
}
