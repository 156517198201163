import {
  WalkPathsRenderer,
  WayPointTarget,
} from "@/components/r3f/renderers/odometry-paths/walk-paths-renderer";
import { useClippingPlanes } from "@/hooks/use-clipping-planes";
import { useDetectTooManyRedraws } from "@/hooks/use-detect-too-many-redraws";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { usePickingToolsCallbacks } from "@/tools/use-picking-tools-callbacks";
import { SceneFilter } from "@/types/scene-filter";
import {
  UniformLights,
  selectAncestor,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { includes } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGenericImgSheet,
  IElementSection,
  compareById,
  isIElementImg360,
  isIElementVideoRecording,
  isIElementVideoRecordingTimeseries,
  isSpaceAnnotation,
} from "@faro-lotv/ielement-types";
import { Quaternion, useThree } from "@react-three/fiber";
import { useCallback, useMemo, useState } from "react";
import { Box3, Vector3 } from "three";
import { selectBestModelCameraFor360 } from "./animations/pano-to-model";
import { WalkControls, WalkControlsProps } from "./walk-controls";
import { WalkSceneCore, WalkSceneCoreProps } from "./walk-scene-core";
import { WalkTools } from "./walk-tools";
import {
  WalkSceneActiveElement,
  WalkSceneOverlayElement,
  sceneFilterFromElement,
} from "./walk-types";

export type WalkSceneProps = Pick<
  WalkSceneCoreProps,
  "onUserWalkedTo" | "onCameraMovedViaAnimation"
> &
  Pick<
    WalkSceneCoreProps,
    | "placeholders"
    | "shouldShowPlaceholders"
    | "hiddenPlaceholders"
    | "viewType"
    | "annotations"
    | "lookAtId"
  > &
  /** The properties for the controls */
  Pick<WalkControlsProps, "onUserInteracted"> & {
    /** Current active element for this scene */
    activeElement: WalkSceneActiveElement;

    /** Active sheet the user is walking into */
    activeSheet?: IElementGenericImgSheet;

    /** All paths to show */
    paths: IElementSection[];

    /** Element to render together with the active one */
    overlayElement?: WalkSceneOverlayElement;

    /** if provided quaternion should be applied while performing pano animation */
    targetQuaternion?: Quaternion;

    /** Callback executed when the local active element inside the walk scene has changed. */
    onActiveElementChanged?(element: IElement): void;

    /** Callback called when the active waypoint target element inside the walk scene has changed. */
    onWayPointChanged(target: WayPointTarget): void;

    /** Callback issued when the camera started a translation. */
    onCameraStartedTranslating?(): void;

    /** Callback issued when the user moved the camera via the exploration controls */
    onCameraMovedViaControls?(pos: Vector3): void;

    /** Callback executed when the animation finishes */
    onAnimationFinished?(active: WalkSceneActiveElement): void;
  };

/**
 * @returns A Scene that handles exploring data at eye level "walking" though @see SceneFilter data.
 */
export function WalkScene({
  activeElement,
  activeSheet,
  paths,
  overlayElement,
  placeholders,
  shouldShowPlaceholders,
  annotations,
  hiddenPlaceholders,
  targetQuaternion,
  viewType,
  lookAtId,
  onUserWalkedTo,
  onCameraMovedViaAnimation,
  onUserInteracted,
  onActiveElementChanged,
  onWayPointChanged,
  onCameraStartedTranslating,
  onCameraMovedViaControls,
  onAnimationFinished,
}: WalkSceneProps): JSX.Element | null {
  useDetectTooManyRedraws("WalkScene");

  const [currentElement, setCurrentElement] = useState(activeElement);
  const [targetElement, setTargetElement] = useState<WalkSceneActiveElement>();
  const activePath = useAppSelector(
    selectAncestor(currentElement, (el): el is IElementSection =>
      includes(paths, el, compareById),
    ),
  );

  const animationFinished = useCallback(
    (active: WalkSceneActiveElement) => {
      setCurrentElement(active);
      setTargetElement(undefined);
      onActiveElementChanged?.(active);
      if (onAnimationFinished) onAnimationFinished(active);
    },
    [onActiveElementChanged, onAnimationFinished],
  );

  const sceneFilter = sceneFilterFromElement(currentElement);
  const camera = useThree((s) => s.camera);

  /**
   * We want to trigger animation only once per change the active element.
   * All other dependencies should not trigger animation.
   * In order to avoid too many redundant calls of onWayPointChanged() using here
   * useNonExhaustiveEffect instead of useEffect
   */
  useNonExhaustiveEffect(() => {
    if (activeElement !== currentElement) {
      setTargetElement(activeElement);
    }
  }, [activeElement]);

  const store = useAppStore();

  const spaceAnnotations = useMemo(
    () => annotations.filter(isSpaceAnnotation),
    [annotations],
  );

  const { tools, ...toolEvents } = usePickingToolsCallbacks();

  const [isToolActive, setIsToolActive] = useState(false);

  const onControlsMovementFinished = useCallback(() => {
    onCameraMovedViaControls?.(camera.position.clone());
  }, [camera, onCameraMovedViaControls]);

  const { clippingPlanes, setClippingPlanes } = useClippingPlanes();

  const [allModelsBox, setAllModelsBox] = useState<Box3>(new Box3());

  const [clippingBoxChanging, setClippingBoxChanging] = useState(false);

  return (
    <>
      <UniformLights />
      <WalkSceneCore
        currentElement={currentElement}
        targetElement={targetElement}
        overlayElement={overlayElement}
        sheet={activeSheet}
        viewType={viewType}
        placeholders={placeholders}
        shouldShowPlaceholders={shouldShowPlaceholders}
        targetQuaternion={targetQuaternion}
        annotations={spaceAnnotations}
        hiddenPlaceholders={hiddenPlaceholders}
        lookAtId={lookAtId}
        onAnimationFinished={animationFinished}
        onUserWalkedTo={onUserWalkedTo}
        onCameraMovedViaAnimation={onCameraMovedViaAnimation}
        onTargetElementChanged={setTargetElement}
        onWayPointChanged={onWayPointChanged}
        isToolActive={isToolActive}
        {...toolEvents}
        clippingPlanes={clippingPlanes}
        renderOnDemand={!clippingBoxChanging}
        onAllModelsBoxChanged={setAllModelsBox}
      />
      <WalkTools
        currentElement={currentElement}
        overlayElement={overlayElement}
        ref={tools}
        onToolActiveChanged={setIsToolActive}
        modelBox={allModelsBox}
        clippingPlanesChanged={setClippingPlanes}
        clippingBoxChanging={setClippingBoxChanging}
      />
      <WalkControls
        onUserInteracted={onUserInteracted}
        canMove={!isIElementImg360(activeElement)}
        onCameraStartedTranslating={onCameraStartedTranslating}
        onCameraStoppedTranslating={onControlsMovementFinished}
      />
      {!isToolActive && (
        <WalkPathsRenderer
          fadeOff
          activePano={
            isIElementImg360(currentElement) ? currentElement : undefined
          }
          activePath={activePath}
          paths={paths}
          alignmentRequired={sceneFilter === SceneFilter.Pano}
          activeSheet={activeSheet}
          onWayPointChanged={onWayPointChanged}
          setActiveElement={(el) => {
            if (!el) return;
            if (
              isIElementImg360(currentElement) ||
              isIElementVideoRecording(currentElement) ||
              isIElementVideoRecordingTimeseries(currentElement)
            ) {
              setTargetElement(el);
            } else {
              onUserWalkedTo(
                selectBestModelCameraFor360(el, activeSheet)(store.getState()),
              );
            }
          }}
        />
      )}
    </>
  );
}
