import {
  PointCloudAnalysis,
  setAnalysisTolerance,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch } from "@/store/store-hooks";
import {
  convertUnit,
  MeasurementUnits,
} from "@faro-lotv/app-component-toolbox";
import {
  ColorBar,
  ColorsWithRatio,
  Dropdown,
  FaroText,
  neutral,
  TextField,
} from "@faro-lotv/flat-ui";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { areColormapsSame, Colormap, ColormapPoints } from "@faro-lotv/lotv";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Color } from "three";

enum ColormapPresetNames {
  rainbow = "rainbow",
  grayscale = "grayscale",
  blueGreenRed = "blueGreenRed",
  blueRedBlue = "blueRedBlue",
}

type ColormapPresetNamesKey = keyof typeof ColormapPresetNames;

/**
 * @param name The name to check
 * @returns True if the given name string is a colormap preset name
 */
function isColormapPresetName(name: string): name is ColormapPresetNamesKey {
  return name in ColormapPresetNames;
}

export const colormapPresets: Record<ColormapPresetNamesKey, Colormap> = {
  rainbow: [
    { value: 0.0, color: new Color("blue") },
    { value: 0.2, color: new Color("cyan") },
    { value: 0.5, color: new Color("lime") },
    { value: 0.8, color: new Color("yellow") },
    { value: 1.0, color: new Color("red") },
  ],
  grayscale: [
    { value: 0.0, color: new Color("black") },
    { value: 1.0, color: new Color("white") },
  ],
  blueGreenRed: [
    { value: 0.0, color: new Color("blue") },
    { value: 0.5, color: new Color("green") },
    { value: 1.0, color: new Color("red") },
  ],
  blueRedBlue: [
    { value: 0.0, color: new Color("blue") },
    { value: 0.5, color: new Color("red") },
    { value: 1.0, color: new Color("blue") },
  ],
};

const colormapOptions = [
  {
    key: ColormapPresetNames.rainbow,
    label: "Rainbow",
    value: ColormapPresetNames.rainbow,
  },
  {
    key: ColormapPresetNames.grayscale,
    label: "Grayscale",
    value: ColormapPresetNames.grayscale,
  },
  {
    key: ColormapPresetNames.blueGreenRed,
    label: "Blue-green-red",
    value: ColormapPresetNames.blueGreenRed,
  },
  {
    key: ColormapPresetNames.blueRedBlue,
    label: "Blue-red-blue",
    value: ColormapPresetNames.blueRedBlue,
  },
];

type ColormapOptionsProps = {
  /** Colormap points for the active analysis. */
  colormapPoints: ColormapPoints;

  /** Active analysis object to be modified. */
  analysis: PointCloudAnalysis;

  /** Current selected unit of measure used to display the distances */
  unitOfMeasure: SupportedUnitsOfMeasure;
};

/** @returns Colormap options UI panel */
export function ColormapOptionsPanel({
  colormapPoints,
  analysis,
  unitOfMeasure,
}: ColormapOptionsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [colormapName, setColormapName] = useState(() => {
    for (const [key, value] of Object.entries(colormapPresets)) {
      if (areColormapsSame(value, colormapPoints.colormap)) {
        return key;
      }
    }
  });

  const getColors = useCallback(
    (): ColorsWithRatio =>
      colormapPoints.colormap.map((colorKey) => ({
        color: `#${colorKey.color.getHexString()}`,
        ratio: colorKey.value,
      })),
    [colormapPoints.colormap],
  );

  const [colorBarColors, setColorBarColors] = useState(getColors);

  const changeColormap = useCallback(
    (newColormap: string) => {
      setColormapName(newColormap);
      if (isColormapPresetName(newColormap)) {
        colormapPoints.colormap = colormapPresets[newColormap];
      }
      setColorBarColors(getColors());
    },
    [colormapPoints, getColors],
  );

  const [toleranceText, setToleranceText] = useState("");
  useEffect(() => {
    setToleranceText(
      unitOfMeasure === "metric"
        ? convertUnit(
            analysis.tolerance,
            MeasurementUnits.meters,
            MeasurementUnits.millimeters,
          ).toFixed(0)
        : convertUnit(
            analysis.tolerance,
            MeasurementUnits.meters,
            MeasurementUnits.inches,
          ).toFixed(3),
    );
  }, [analysis.tolerance, unitOfMeasure]);

  const [toleranceError, setToleranceError] = useState("");
  const changeTolerance = useCallback(
    (newText: string) => {
      setToleranceText(newText);
      const newTolerance = Number(newText);
      if (isNaN(newTolerance)) {
        setToleranceError("Invalid number");
      } else {
        const tolerance = Math.abs(
          unitOfMeasure === "metric"
            ? convertUnit(
                newTolerance,
                MeasurementUnits.millimeters,
                MeasurementUnits.meters,
              )
            : convertUnit(
                newTolerance,
                MeasurementUnits.inches,
                MeasurementUnits.meters,
              ),
        );
        colormapPoints.minColorDeviation = -tolerance;
        colormapPoints.maxColorDeviation = +tolerance;
        setToleranceError("");
        dispatch(setAnalysisTolerance({ analysisId: analysis.id, tolerance }));
      }
    },
    [analysis.id, colormapPoints, dispatch, unitOfMeasure],
  );

  return (
    <Grid container spacing={1} alignItems="center" sx={{ width: "400px" }}>
      <Grid item xs={5}>
        <Dropdown
          options={colormapOptions}
          value={colormapName}
          dark
          onChange={(e) => changeColormap(e.target.value)}
        />
      </Grid>
      <Grid item xs={7}>
        <ColorBar colors={colorBarColors} />
      </Grid>
      <Grid item xs={5}>
        <FaroText variant="labelL" sx={{ color: neutral[0] }}>
          Tolerance
        </FaroText>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          dark
          text={toleranceText}
          error={toleranceError}
          onTextChanged={changeTolerance}
        />
      </Grid>
      <Grid item xs={1}>
        <FaroText variant="labelL" sx={{ color: neutral[0] }}>
          {unitOfMeasure === "metric" ? "mm" : "in"}
        </FaroText>
      </Grid>
    </Grid>
  );
}
